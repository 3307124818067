import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'

import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'


const news = () => {
    return (
        <Layout pageTitle="PragICTS | Blog">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>Blog</h2>
                   
                                <div className="horizonral-subtitle"><span>Blog</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >BLOG <span>//</span></div>
            <div className="container">
               
                <div className="custom-inner-holder  mar-bottom">
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Information Technology Infrastructure Library (ITIL), A Must for IT Operations</h4>
                                    <p>Wednesday, April 03, 2024</p>
                                    <p>ITIL is a globally recognized framework that standardizes the selection, planning, delivery, and maintenance of IT services within a business. It is a framework designed to standardize IT service management practices, ensuring efficient service delivery aligned with business goals and aims to enhance efficiency, ensure predictable service delivery, and align IT activities with business requirements. By adopting ITIL, organizations can transform IT administrators into strategic business partners rather than just support staff....</p>
                                   
                                    <a href="https://pragicts.blogspot.com/2024/04/information-technology-infrastructure.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                    <a href="https://www.linkedin.com/pulse/information-technology-infrastructure-library-itil-must-ahamedh-umar-r6l1c/?trackingId=n27ylPis5TC5r2ZbjdYFKQ%3D%3D" Style="position: absolute;right: 30px;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-linkedin'></span></a>
                                 
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Top Use Cases for Firewalls</h4>
                                    <p>Wednesday, April 03, 2024</p>
                                    <p>Firewalls play a pivotal role in network and cybersecurity, offering essential protection against a wide range of threats. Here's a concise overview of their top use cases: 1.   Perimeter Protection: Firewalls establish a barrier between internal networks and the Internet, filtering incoming and outgoing traffic based on predefined rules....</p>
                                    <a href="https://pragicts.blogspot.com/2024/04/top-use-cases-for-firewalls.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Cybersecurity, The Nuts And Bolts</h4>
                                    <p>Saturday, March 30, 2024</p>
                                    <p>SOME FACTS A cyberattack strikes every 39 seconds! In 2024, the global cost of cybercrime is projected to reach a staggering USD 9.5 trillion, emphasizing the urgent need for cybersecurity to be a top global priority....</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/cybersecurity-nuts-and-bolts.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                    <a href="https://www.linkedin.com/pulse/cybersecurity-nuts-bolts-pragicts-3dhhc/?trackingId=bnl5sOziRTKGSou0znP71Q%3D%3D" Style="position: absolute;right: 30px;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-linkedin'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>AI,  is it fast getting commoditized? Where will the edge be?</h4>
                                    <p>Wednesday, March 27, 2024</p>
                                    <p>Artificial intelligence (AI) is progressively transitioning into a commodity, characterized by increased accessibility and integration into diverse products and services. These AI offerings are being bundled and presented as services, making them easily available to both businesses and individuals....</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/ai-is-it-fast-getting-commoditized.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Cyber Security is not simple.</h4>
                                    <p>Wednesday, March 27, 2024</p>
                                    <p>Cyber security is not simple. It is beyond a single vendor/product solution and/or an ad hoc approach...</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/cyber-security-is-not-simple.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Automation, Why it is an essential milestone to achieve</h4>
                                    <p>Wednesday, March 27, 2024</p>
                                    <p>The ongoing evolution of technology ensures that the reach and impact of digitization will continue to expand. It also creates a great extensive digital fabric for automation. Automation in general brings on board...</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/automation-why-it-is-essential.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;"  target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Top Usecases for Firewalls</h4>
                                    <p>Wednesday, March 27, 2024</p>
                                    <p>Firewalls play a pivotal role in network and cybersecurity, offering essential protection against a wide range of threats...</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/top-usecases-for-firewalls.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Demystifying Cyber Security</h4>
                                    <p>Monday, March 25, 2024</p>
                                    <p>To start of with, what is cyber security? Cybersecurity is the practice of protecting internet-connected systems such as hardware, software and data from cyber threats. It's used by individuals and enterprises to protect against unauthorized access to data centers and other computerized systems. The digital threat landscape is numerous, disruptive with innovation, competing technologies, choices, alternatives, active and hence fast paced...</p>
                                    <a href="https://pragicts.blogspot.com/2024/03/demystifying-cyber-security.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>The Context For eCommerce</h4>
                                    <p>Saturday, October 31, 2020</p>
                                    <p>Present Context There is no foreseeable control or ending to the raging global pandemic. This is driving the need and realization for online fulfillment and convenience. As the COVID-19 pandemic reshapes our world, more consumers have begun shopping online in greater numbers and frequency. According to new data from IBM’s U.S. Retail Index, the pandemic has accelerated the shift away from physical stores to digital shopping...</p>
                                    <a href="https://pragicts.blogspot.com/2020/10/the-context-for-ecommerce.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Divergent Perspectives - Internet/eCommerce Statistics</h4>
                                    <p>Wednesday, October 28, 2020</p>
                                    <p>The internet serves as an unparalleled global connector, offering boundless opportunities for those who are bold, brave, and creative. Consider the staggering statistics: Key Figures Global Population: 8.1 Billion Mobile Users: 6.3 Billion (68%) Internet Users: 5.3 Billion (66%) Social Media Users: 4.9 Billion (60%) Detailed Insights The average person spends 6 hours and 30 minutes...</p>
                                    <a href="https://pragicts.blogspot.com/2020/10/divergent-perspectives.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>IOT - The Next Frontier Of Online Expansion</h4>
                                    <p>Thursday, July 30, 2020</p>
                                    <p>he mobile is taking the Internet accessibility to the remaining frontiers of the global population. Initiatives such as Tesla's Star-link project aims for global accessibility of high-speed internet at affordable rates. Cloud based computing is making it possible for ubiquitous real-time computing capability. The next phase of the internet expansion is now being...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/iot-next-frontier-of-online-expansion.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Voice - The Next Phase Of Digital Interaction</h4>
                                    <p>Monday, July 27, 2020</p>
                                    <p>All major players are into it and also with many solutions. However, these are still at its infancy and are set to become mainstream in the days ahead. Voice will provide that next level of ease of interaction with Digital. While this will not replace the existing methods but will for sure carve out a significant share of human to digital interactions and will also extend to Digital to Digital Interactions...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/voice-next-phase-of-digital-interaction.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>'H' - The Hyped Part of HRM / HCM</h4>
                                    <p>Saturday, July 25, 2020</p>
                                    <p>While there has been notable progress in Human Resource Management (HRM) over recent decades, there remains a pervasive sense of unmet potential. HRM has often been entangled in public relations and marketing endeavors rather than earnestly considering how to strike a harmonious balance...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/h-underemphasized-part-of-hrm-hcm.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>

                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Open Source Or Commercial Software?</h4>
                                    <p>Saturday, July 25, 2020</p>
                                    <p>The debate between open-source and commercial software is nuanced, with both options presenting their own advantages and drawbacks. Each has its own user base, deployments, and dedicated supporters, showcasing their respective strengths. Ultimately, the effectiveness of either depends on the specific context in which they're being considered...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/open-source-or-commercial-software.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;" target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                
                
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Online Presence - A Must Have</h4>
                                    <p>Friday, July 24, 2020</p>
                                    <p>An online presence refers to the digital footprint or visibility that an entity maintains on the internet. While having a website or social media accounts can contribute to an online presence, simply having these platforms does not guarantee effective online visibility. Yes, having a website provides a foundational base for your online presence, offering a unique online address...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/online-presence.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;"  target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
               
               
                <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Keep It Simple</h4>
                                    <p>Friday, July 24, 2020</p>
                                    <p>Simplicity is key to success, a principle that applies across all aspects of life, including IT and digital endeavors. In the fast-paced world of IT, constant innovation disrupts established practices. Striving for perfection in such a dynamic environment can be counterproductive...</p>
                                    <a href="https://pragicts.blogspot.com/2020/07/keep-it-simple-sensible-elegant.html" Style="position: absolute;right: 0;bottom: 0;color: red;margin: 5px 20px;font-size: 25px;"  target="_blank"><span className='fab fa-blogger'></span></a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                    
                

                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>

  
    )
}

export default news
